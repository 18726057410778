import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  CardTitle,
  Col,
  Form,
  Label,
  Row,
  Modal,
  ModalBody,
  Container,
  Card,
  CardBody,
  Spinner, ModalHeader,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import APIS from "../../apis"
import { withTranslation } from "react-i18next"
import Select from "react-select"

const EditFicheVoeux = props => {
  var userAuthEnseignant = JSON.parse(localStorage.getItem("userAuthEnseignant"))
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var departement_id =userAuthEnseignant.user.departement_id
  var annee_id =userAuthEnseignant.user.annee_id
  //
  var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
  var anneeUniversitaire = anneeScolaire.label
  const [jours, setJours] = useState([
    { value: 1, label: "Lundi" },
    { value: 2, label: "Mardi" },
    { value: 3, label: "Mercredi" },
    { value: 4, label: "Jeudi" },
    { value: 5, label: "Vendredi" },
    { value: 6, label: "Samedi" },
  ])
  const [selectJour, setSelectJour] = useState("")
  const [niveau, setNiveau] = useState([])
  const [niveau_id, setNiveau_id] = useState("")
  const [niveau1, setNiveau1] = useState([])
  const [niveau_id1, setNiveau_id1] = useState("")
  const [type, setType] = useState([])
  const [type1, setType1] = useState([])
  const [specialite, setSpecialite] = useState([])
  const [specialite1, setSpecialite1] = useState([])
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const [matiere, setMatiere] = useState([])
  const [matiere1, setMatiere1] = useState([])
  const [arrayFiche, setArrayFiche] = useState([
    {
      etat: 0,
      ordre: "",
      niveau_id: "",
      specialite_id: "",
      matiere_id: "",
      type: "",
    },
  ])
  const [arrayFiche1, setArrayFiche1] = useState([
    {
      etat1: 0,
      ordre1: "",
      niveau_id1: "",
      specialite_id1: "",
      matiere_id1: "",
      type1: "",
    },
  ])
  const [modal, setModal] = useState(false)
  const [indexLigne, setIndexLigne] = useState("")
  const [modal1, setModal1] = useState(false)
  const [indexLigne1, setIndexLigne1] = useState("")
  const [loading, setLoading] = useState(false)
  const [ordre, setOrdre] = useState([
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
  ])
  const [session, setSession] = useState([
    {value:1,label:"Matin"},
    {value:2,label:"Après-Midi"},
  ])
  const [modalJours, setModalJours] = useState(false)
  const [arrayJour, setArrayJour] = useState([{ jour_id: "" ,session:""}])
  const [modalDetail, setModalDetail] = useState(false)
  const [title, setTitle] = useState("")
  const [msg, setMsg] = useState("")
  const addLigneJour = () => {
    let element = {
      jour_id: "",
      session: "",
    }
    setArrayJour([...arrayJour, element])
  }
  const addJour = (event, index) => {
    let jour_id = event
    setArrayJour(
        arrayJour.map((el, id) =>
            id === index ? Object.assign(el, { jour_id: jour_id }) : el
        )
    )
  }
  const addSession = (event, index) => {
    let session = event
    setArrayJour(
        arrayJour.map((el, id) =>
            id === index ? Object.assign(el, { session: session }) : el
        )
    )
  }
  const [indexLigneJours, setIndexLigneJours] = useState("")
  useEffect(async () => {
    const resN = await APIS.get("niveau/select").then(resN => {
      setNiveau(resN.data.Niveau)
    })
    const resN1 = await APIS.get("niveau/select_mastere").then(resN1 => {
      setNiveau1(resN1.data.Niveau)
    })
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await APIS.post("fiche/get_by_id_edit", { id }).then(res => {
      setSelectSemestre(res.data.FicheVoeau.semestre_id)
      setSelectJour(res.data.FicheVoeau.jour_id)
      setArrayFiche(res.data.FicheVoeau.ligne)
      setArrayFiche1(res.data.FicheVoeau.ligne1)
      setArrayJour(res.data.FicheVoeau.jour_id)
      setLoading(true)
    })
  }, [])
  const toggleJours = indexOfLigne => {
    setModalJours(!modalJours)
    setIndexLigne(indexOfLigne)
  }
  const toggleDeleteJours = indexOfLigne => {
    arrayJour.splice(indexLigneJours, 1)
    setModalJours(false)
  }
  const addLigne = () => {
    let element = {
      etat: 0,
      ordre: "",
      niveau_id: "",
      specialite_id: "",
      matiere_id: "",
      type: "",
    }
    setArrayFiche([...arrayFiche, element])
  }

  const addLigne1 = () => {
    let element = {
      etat1: 0,
      ordre1: "",
      niveau_id1: "",
      specialite_id1: "",
      matiere_id1: "",
      type1: "",
    }
    setArrayFiche1([...arrayFiche1, element])
  }

  const toggle = indexOfLigne => {
    setModal(!modal)
    setIndexLigne(indexOfLigne)
  }

  const toggle1 = indexOfLigne1 => {
    setModal1(!modal1)
    setIndexLigne1(indexOfLigne1)
  }

  const toggleDelete = async () => {
    arrayFiche.splice(indexLigne, 1)
    setModal(false)
  }

  const toggleDelete1 = async () => {
    arrayFiche1.splice(indexLigne1, 1)
    setModal1(false)
  }

  const addOrdre = (event, index) => {
    let ordre = event
    setArrayFiche(
        arrayFiche.map((el, id) =>
            id === index ? Object.assign(el, { ordre: ordre }) : el
        )
    )
  }

  const addNiveau = (event, index) => {
    let niveau_id = event
    setNiveau_id(event.value)
    const res = APIS.post("specialite/get_by_niveau_licence", {
      niveau_id: event.value,
      enseignant_id: enseignant_id,
      semestre_id: selectSemestre.value,
    }).then(res => {
      setSpecialite(res.data.Specialites)
    })
    setArrayFiche(
        arrayFiche.map((el, id) =>
            id === index ? Object.assign(el, { niveau_id: niveau_id }) : el
        )
    )
  }

  const addSpecialite = (event, index) => {
    let specialite = event
    const res = APIS.post("matiere/get_by_specialite", {
      specialite_id: event.value,
      enseignant_id: enseignant_id,
      semestre_id: selectSemestre.value,
      niveau_id: niveau_id,
    }).then(res => {
      setMatiere(res.data.Matiere)
    })
    setArrayFiche(
        arrayFiche.map((el, id) =>
            id === index ? Object.assign(el, { specialite_id: specialite }) : el
        )
    )
  }

  const addMatiere = (event, index) => {
    let matiere = event
    const res = APIS.post("matiere/get_detail", {
      matiere_id: event.value,
    }).then(res => {
      setType(res.data.Matiere)
    })
    setArrayFiche(
        arrayFiche.map((el, id) =>
            id === index ? Object.assign(el, { matiere_id: matiere }) : el
        )
    )
  }

  const addType = (event, index) => {
    let type = event
    setArrayFiche(
        arrayFiche.map((el, id) =>
            id === index ? Object.assign(el, { type: type }) : el
        )
    )
  }

  const addOrdre1 = (event, index) => {
    let ordre = event
    setArrayFiche1(
        arrayFiche1.map((el, id) =>
            id === index ? Object.assign(el, { ordre1: ordre }) : el
        )
    )
  }

  const addNiveau1 = (event, index) => {
    let niveau_id = event
    setNiveau_id1(event.value)
    const res = APIS.post("specialite/get_by_niveau_mastere", {
      niveau_id: event.value,
      enseignant_id: enseignant_id,
      semestre_id: selectSemestre.value,
    }).then(res => {
      setSpecialite1(res.data.Specialites)
    })
    setArrayFiche1(
        arrayFiche1.map((el, id) =>
            id === index ? Object.assign(el, { niveau_id1: niveau_id }) : el
        )
    )
  }

  const addSpecialite1 = (event, index) => {
    let specialite = event
    const res = APIS.post("matiere/get_by_specialite2", {
      specialite_id: event.value,
      enseignant_id: enseignant_id,
      semestre_id: selectSemestre.value,
      niveau_id: niveau_id1,
      departement_id:departement_id
    }).then(res => {
      setMatiere1(res.data.Matiere)
    })
    setArrayFiche1(
        arrayFiche1.map((el, id) =>
            id === index ? Object.assign(el, { specialite_id1: specialite }) : el
        )
    )
  }

  const addMatiere1 = (event, index) => {
    let matiere = event
    const res = APIS.post("matiere/get_detail", {
      matiere_id: event.value,
    }).then(res => {
      setType1(res.data.Matiere)
    })
    setArrayFiche1(
        arrayFiche1.map((el, id) =>
            id === index ? Object.assign(el, { matiere_id1: matiere }) : el
        )
    )
  }

  const addType1 = (event, index) => {
    let type = event
    setArrayFiche1(
        arrayFiche1.map((el, id) =>
            id === index ? Object.assign(el, { type1: type }) : el
        )
    )
  }
  const toggleDetail = async (title,msg) => {
    setModalDetail(!modalDetail)
    if(modalDetail !== true){
      setTitle(title)
      setMsg(msg)
    }
  }
  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if((arrayJour.length > 0 && arrayJour.every(item => item.jour_id && item.session))){
      const hasDuplicates = arrayJour.some((item, index) =>
        arrayJour.findIndex(el => el.jour_id === item.jour_id && el.session === item.session) !== index
      )
      if (hasDuplicates) {
        toggleDetail("Ce jour et cette session sont déjà sélectionnés.","⛔ Ce jour et cette session sont déjà sélectionnés.")

      } else {
        if (arrayJour.length < 4) {
          toggleDetail("Vous devez avoir au moins 4 lignes","⛔ Vous devez avoir au moins 4 lignes (ça vous dit deux jours matin et après-midi ou 4 jours)")
        }
      }
    }

    // if (selectSemestre != "") {
    //   if (selectJour.length > 0) {
    //     const res = await APIS.post("fiche/edit", {
    //       id: id,
    //       semestre_id: selectSemestre.value,
    //       ligne: arrayFiche,
    //       ligne1: arrayFiche1,
    //       jour: arrayJour,
    //     })
    //     if (res.data.msg === "order invalide") {
    //       toast.error("Ordre invalide", {
    //         containerId: "A",
    //       })
    //     } else {
    //       toast.success(" Votre fiche de voeux est ajouté avec succées ", {
    //         containerId: "A",
    //       })
    //       props.history.push("/FicheVoeux")
    //     }
    //   } else {
    //     toast.error("Veuillez choisir les jours", {
    //       containerId: "A",
    //     })
    //   }
    // }
    // else {
    //   toast.error("Veuillez choisir un semestre ", {
    //     containerId: "A",
    //   })
    // }
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Card>
              <CardBody>
                {loading ? (
                    <Row>

                      <Form>

                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                {props.t("Semestre")} :
                              </Label>
                              <Select
                                  options={semestre}
                                  isSearchable={true}
                                  value={selectSemestre}
                                  onChange={e => setSelectSemestre(e)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Label for="basicpill-firstname-input1">
                            {props.t("Choix des jours")} :
                          </Label>
                          <table className="table table-bordered mt-4">
                            <thead>
                            <tr>
                              <th style={{textAlign: "center"}} scope="col">
                                Jours
                              </th>
                              <th style={{textAlign: "center"}} scope="col">
                                Choix du jours
                              </th>
                              <th style={{textAlign: "center"}} scope="col">
                                <i
                                    style={{
                                      color: "#a0ceb8",
                                      cursor: "pointer",
                                    }}
                                    className="fas fa-plus"
                                    onClick={addLigneJour}
                                />
                              </th>
                            </tr>
                            </thead>
                            {arrayJour.map((el, index) => (
                                <tbody key={index}>
                                <tr>
                                  <td style={{textAlign: "center"}} scope="col">
                                    <Select
                                        options={jours}
                                        isSearchable={true}
                                        value={el.jour_id}
                                        onChange={e => addJour(e, index)}
                                    />
                                  </td>
                                  <td style={{textAlign: "center"}} scope="col">
                                    <Select
                                        value={el.session}
                                        options={session}
                                        isSearchable={true}
                                        onChange={e => addSession(e, index)}
                                    />
                                  </td>
                                  <td className="col-md-2" style={{textAlign: "center"}}>
                                    <i
                                        style={{color: "#cc0000"}}
                                        className="mdi mdi-delete font-size-18"
                                        id="deletetooltip"
                                        onClick={() => toggleJours(index)}
                                    />
                                  </td>
                                </tr>
                                </tbody>
                            ))}
                          </table>
                        </Row>
                        <div className="mt-4 mb-4">
                          <h5>
                            Veuillez indiquer les matières enseignées au cours de ce semestre de l’année universitaire {anneeUniversitaire} en respectant la répartition pédagogique établie par votre directeur de département, préciser la forme d’enseignement correspondante (Cours, TD, TP, Autre).
                          </h5>
                        </div>
                        <CardTitle
                            style={{ color: "#556ee6" }}
                            className="h4 mb-2 mt-2"
                        >
                          Choix des matiéres pour licence:
                        </CardTitle>
                        <table className="table table-bordered mt-4">
                          <thead>
                          <tr>
                            <th style={{ textAlign: "center" }} scope="col">
                              Ordre
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Niveau
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Spécialité
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Matiere proposé
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Type seance
                            </th>
                            {arrayFiche.length + arrayFiche1.length <= 9 ? (
                                <th style={{ textAlign: "center" }} scope="col">
                                  <i
                                      style={{
                                        color: "#a0ceb8",
                                        cursor: "pointer",
                                      }}
                                      className="fas fa-plus"
                                      onClick={addLigne}
                                  />
                                </th>
                            ) : null}
                          </tr>
                          </thead>
                          {arrayFiche.map((el, index) => (
                              <tbody key={index}>
                              <tr>
                                {el.etat == 0 ? (
                                    <td className="col-md-2">
                                      <Select
                                          options={ordre}
                                          isSearchable={true}
                                          value={el.ordre}
                                          onChange={e => addOrdre(e, index)}
                                      />
                                    </td>
                                ) : (
                                    <td className="col-md-2">
                                      <Select
                                          isSearchable={true}
                                          value={el.ordre}
                                          disabled
                                          onChange={e => addOrdre(e, index)}
                                      />
                                    </td>
                                )}
                                {el.etat == 0 ? (
                                    <td className="col-md-2">
                                      <Select
                                          options={niveau}
                                          isSearchable={true}
                                          onChange={e => addNiveau(e, index)}
                                          value={el.niveau_id}
                                      />
                                    </td>
                                ) : (
                                    <td className="col-md-2">
                                      <Select
                                          isSearchable={true}
                                          disabled
                                          onChange={e => addNiveau(e, index)}
                                          value={el.niveau_id}
                                      />
                                    </td>
                                )}
                                {el.etat == 0 ? (
                                    <td className="col-md-3">
                                      <Select
                                          options={specialite}
                                          isSearchable={true}
                                          onChange={e => addSpecialite(e, index)}
                                          value={el.specialite_id}
                                      />
                                    </td>
                                ) : (
                                    <td className="col-md-3">
                                      <Select
                                          isSearchable={true}
                                          disabled
                                          onChange={e => addSpecialite(e, index)}
                                          value={el.specialite_id}
                                      />
                                    </td>
                                )}

                                {el.etat == 0 ? (
                                    <td className="col-md-3">
                                      <Select
                                          options={matiere}
                                          isSearchable={true}
                                          value={el.matiere_id}
                                          onChange={e => addMatiere(e, index)}
                                      />
                                    </td>
                                ) : (
                                    <td className="col-md-3">
                                      <Select
                                          isSearchable={true}
                                          disabled
                                          value={el.matiere_id}
                                          onChange={e => addMatiere(e, index)}
                                      />
                                    </td>
                                )}
                                {el.etat == 0 ? (
                                    <td className="col-md-2">
                                      <Select
                                          options={type}
                                          isSearchable={true}
                                          value={el.type}
                                          onChange={e => addType(e, index)}
                                      />
                                    </td>
                                ) : (
                                    <td className="col-md-2">
                                      <Select
                                          isSearchable={true}
                                          disabled
                                          value={el.type}
                                          onChange={e => addType(e, index)}
                                      />
                                    </td>
                                )}
                                {el.etat == 0 ? (
                                    <td
                                        className="col-md-1"
                                        style={{ textAlign: "center" }}
                                    >
                                      <i
                                          style={{
                                            color: "#cc0000",
                                            cursor: "pointer",
                                          }}
                                          className="mdi mdi-delete font-size-18"
                                          id="deletetooltip"
                                          onClick={() => toggle(index)}
                                      />
                                    </td>
                                ) : (
                                    <td
                                        className="col-md-1"
                                        style={{ textAlign: "center" }}
                                    ></td>
                                )}
                              </tr>
                              </tbody>
                          ))}
                        </table>
                        <CardTitle
                            style={{ color: "#556ee6" }}
                            className="h4 mb-2 mt-2"
                        >
                          Choix des matiéres pour mastère:
                        </CardTitle>
                        <table className="table table-bordered mt-4">
                          <thead>
                          <tr>
                            <th style={{ textAlign: "center" }} scope="col">
                              Ordre
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Niveau
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Spécialité
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Matiere proposé
                            </th>
                            <th style={{ textAlign: "center" }} scope="col">
                              Type seance
                            </th>
                            {arrayFiche.length + arrayFiche1.length <= 9 ? (
                                <th style={{ textAlign: "center" }} scope="col">
                                  <i
                                      style={{
                                        color: "#a0ceb8",
                                        cursor: "pointer",
                                      }}
                                      className="fas fa-plus"
                                      onClick={addLigne1}
                                  />
                                </th>
                            ) : null}
                          </tr>
                          </thead>
                          {arrayFiche1.map((el, index) => (
                              <tbody key={index}>
                              <tr>
                                {el.etat1 == 0 ? (
                                    <td className="col-md-2">
                                      <Select
                                          options={ordre}
                                          isSearchable={true}
                                          value={el.ordre1}
                                          onChange={e => addOrdre1(e, index)}
                                      />{" "}
                                    </td>
                                ) : (
                                    <td className="col-md-2">
                                      <Select
                                          isSearchable={true}
                                          disabled
                                          value={el.ordre1}
                                          onChange={e => addOrdre1(e, index)}
                                      />{" "}
                                    </td>
                                )}
                                {el.etat1 == 0 ? (
                                    <td className="col-md-2">
                                      <Select
                                          options={niveau1}
                                          isSearchable={true}
                                          value={el.niveau_id1}
                                          onChange={e => addNiveau1(e, index)}
                                      />
                                    </td>
                                ) : (
                                    <td className="col-md-2">
                                      <Select
                                          isSearchable={true}
                                          disabled
                                          value={el.niveau_id1}
                                          onChange={e => addNiveau1(e, index)}
                                      />
                                    </td>
                                )}
                                {el.etat1 == 0 ? (
                                    <td className="col-md-3">
                                      <Select
                                          options={specialite1}
                                          isSearchable={true}
                                          value={el.specialite_id1}
                                          onChange={e => addSpecialite1(e, index)}
                                      />
                                    </td>
                                ) : (
                                    <td className="col-md-3">
                                      <Select
                                          isSearchable={true}
                                          disabled
                                          value={el.specialite_id1}
                                          onChange={e => addSpecialite1(e, index)}
                                      />
                                    </td>
                                )}
                                {el.etat1 == 0 ? (
                                    <td className="col-md-3">
                                      <Select
                                          options={matiere1}
                                          isSearchable={true}
                                          value={el.matiere_id1}
                                          onChange={e => addMatiere1(e, index)}
                                      />
                                    </td>
                                ) : (
                                    <td className="col-md-3">
                                      <Select
                                          isSearchable={true}
                                          disabled
                                          value={el.matiere_id1}
                                          onChange={e => addMatiere1(e, index)}
                                      />
                                    </td>
                                )}
                                {el.etat1 == 0 ? (
                                    <td className="col-md-2">
                                      <Select
                                          options={type1}
                                          isSearchable={true}
                                          value={el.type1}
                                          onChange={e => addType1(e, index)}
                                      />
                                    </td>
                                ) : (
                                    <td className="col-md-2">
                                      <Select
                                          isSearchable={true}
                                          disabled
                                          value={el.type1}
                                          onChange={e => addType1(e, index)}
                                      />
                                    </td>
                                )}
                                {el.etat1 == 0 ? (
                                    <td
                                        className="col-md-1"
                                        style={{ textAlign: "center" }}
                                    >
                                      <i
                                          style={{
                                            color: "#cc0000",
                                            cursor: "pointer",
                                          }}
                                          className="mdi mdi-delete font-size-18"
                                          id="deletetooltip"
                                          onClick={() => toggle1(index)}
                                      />
                                    </td>
                                ) : (
                                    <td
                                        className="col-md-1"
                                        style={{ textAlign: "center" }}
                                    ></td>
                                )}
                              </tr>
                              </tbody>
                          ))}
                        </table>
                      </Form>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <Col lg="6">
                          <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              className="text-center mt-4"
                          >
                            <Button
                                type="button"
                                color="warning"
                                className="btn btn-warning  mb-2 me-2"
                                onClick={() => props.history.push("/FicheVoeux")}
                            >
                              Annuler
                            </Button>
                          </div>
                        </Col>
                        <Col lg="6">
                          <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                              className="text-center mt-4"
                          >
                            <button
                                type="button"
                                className="btn btn-primary "
                                onClick={edit}
                            >
                              Confirmer
                            </button>
                          </div>
                        </Col>
                      </div>
                    </Row>
                ) : (
                    <div>
                      <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                      >
                        {" "}
                        <Spinner type="grow" className="ms-6" color="primary" />
                      </div>
                      <h4
                          style={{ textAlign: "center", marginTop: "2%" }}
                          className="ms-6"
                      >
                        {" "}
                        {"Chargement"} ...{" "}
                      </h4>
                    </div>
                )}
                <ToastContainer
                    transition={Slide}
                    enableMultiContainer
                    containerId={"A"}
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={2500}
                />
              </CardBody>
            </Card>
            <Modal isOpen={modalDetail} toggle={toggleDetail} centered={true}>
              <div
                className="modal-header"
                style={{
                  width: "100% !important",
                  justifyContent: "center !important",
                  margin: "0 auto",
                  fontSize: "17px"
                }}
                toggle={toggleDetail}
              >
                {title}
              </div>
              <ModalBody>
                <div
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    FontSize: "14px",
                    FontWeight: "700",
                    LineHeight: "18.375px"
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p>{msg}</p>
                  </div>
                  <div
                    className="hvr-push"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginBottom: "15px",
                      marginTop:"17px"
                    }}
                  >

                    <div>
                      <Button
                        onClick={toggleDetail}
                        color="danger"
                        className="btn-rounded "
                      >
                        <i
                          style={{ color: "white" }}
                          className="far fa-thumbs-down"
                        ></i>
                        Fermer
                      </Button>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
              <div
                  className="modal-header"
                  style={{
                    width: "100% !important",
                    justifyContent: "center !important",
                    margin: "0 auto",
                    fontSize: "17px",
                  }}
                  toggle={toggle}
              >
                {props.t("Suppression ligne")}
              </div>
              <ModalBody>
                <div
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      FontSize: "14px",
                      FontWeight: "700",
                      LineHeight: "18.375px",
                    }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                  </div>
                  <div
                      className="hvr-push"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginBottom: "15px",
                      }}
                  >
                    <div>
                      <Button
                          onClick={toggleDelete}
                          color="info"
                          className="btn-rounded "
                      >
                        <i
                            style={{ color: "white" }}
                            className="far fa-thumbs-up"
                        ></i>
                        Oui
                      </Button>
                    </div>
                    <div>
                      <Button
                          onClick={toggle}
                          color="danger"
                          className="btn-rounded "
                      >
                        <i
                            style={{ color: "white" }}
                            className="far fa-thumbs-down"
                        ></i>
                        Non
                      </Button>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            <Modal isOpen={modal1} toggle={toggle1} centered={true}>
              <div
                  className="modal-header"
                  style={{
                    width: "100% !important",
                    justifyContent: "center !important",
                    margin: "0 auto",
                    fontSize: "17px",
                  }}
                  toggle={toggle1}
              >
                {props.t("Suppression ligne")}
              </div>
              <ModalBody>
                <div
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      FontSize: "14px",
                      FontWeight: "700",
                      LineHeight: "18.375px",
                    }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                  </div>
                  <div
                      className="hvr-push"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginBottom: "15px",
                      }}
                  >
                    <div>
                      <Button
                          onClick={toggleDelete1}
                          color="info"
                          className="btn-rounded "
                      >
                        <i
                            style={{ color: "white" }}
                            className="far fa-thumbs-up"
                        ></i>
                        Oui
                      </Button>
                    </div>
                    <div>
                      <Button
                          onClick={toggle}
                          color="danger"
                          className="btn-rounded "
                      >
                        <i
                            style={{ color: "white" }}
                            className="far fa-thumbs-down"
                        ></i>
                        Non
                      </Button>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            <Modal isOpen={modalJours} toggle={toggleJours} centered={true}>
              <ModalHeader toggle={toggle} tag="h4">
                {"Suppression d'une ligne"}{" "}
              </ModalHeader>
              <ModalBody>
                <div
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      FontSize: "14px",
                      FontWeight: "700",
                      LineHeight: "18.375px",
                    }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p>Voulez-vous supprimer cette ligne ?</p>
                  </div>
                  <div
                      className="hvr-push"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginBottom: "15px",
                      }}
                  >
                    <div>
                      <Button
                          onClick={toggleDeleteJours}
                          color="info"
                          className="btn-rounded "
                      >
                        <i
                            style={{ color: "white" }}
                            className="far fa-thumbs-up"
                        ></i>
                        Oui
                      </Button>
                    </div>
                    <div>
                      <Button
                          onClick={toggleJours}
                          color="danger"
                          className="btn-rounded "
                      >
                        <i
                            style={{ color: "white" }}
                            className="far fa-thumbs-down"
                        ></i>
                        Non
                      </Button>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
  )
}

export default withTranslation()(EditFicheVoeux)
EditFicheVoeux.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
