import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Col, Form, Label, Row,} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import APIS from "../../apis"
import { withTranslation } from "react-i18next"
import Select from "react-select"

const AddFicheVoeuxSurveillance = props => {
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var departement_id =userAuthEnseignant.user.departement_id
  var annee_id =userAuthEnseignant.user.annee_id
  const [jours, setJours] = useState([
    { value: 1, label: "Lundi" },
    { value: 2, label: "Mardi" },
    { value: 3, label: "Mercredi" },
    { value: 4, label: "Jeudi" },
    { value: 5, label: "Vendredi" },
    { value: 6, label: "Samedi" },
  ])
  
  const [selectJour, setSelectJour] = useState("")
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")

  useEffect(async () => {

  }, [])
  const save = async () => {
    var etat = true
    if(selectSemestre === ""){
      etat = false
      toast.error("⛔ Semestre obligatoire", {
        containerId: "A",
      })
    }else if (selectJour === "") {
      etat=false
      toast.error("⛔ Jours obligatoire", {
        containerId: "A",
      })
    }
    if (etat) {
      const res = await APIS.post("fichesurveillance/store", {
        semestre_id: selectSemestre.value,
        departement_id:departement_id,
        enseignant_id:enseignant_id,
        annee_id:annee_id,
        array_fiche_Surv: selectJour,
      })
      if (res.data.msg === "Fiche voeux ajouté avec succes") {
        toast.success(" Votre fiche de voeux est ajouté avec succées ", {
          containerId: "A",
        })
        props.setSection(1)
      }
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Semestre")} :
                </Label>
                <Select
                    options={semestre}
                    isSearchable={true}
                    onChange={e => setSelectSemestre(e)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Jours")} :
                </Label>
                <Select
                    options={jours}
                    isSearchable={true}
                    isMulti={true}
                    onChange={e => setSelectJour(e)}
                />
              </div>
            </Col>

          </Row>

          <div style={{display: "flex", justifyContent: "center"}}>
            {" "}
            <Col lg="6">
              <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
              >
                <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={props.back}
                >
                  {props.t("Annuler")}
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
              >
                <button
                    type="button"
                    className="btn btn-primary "
                    onClick={save}
                >
                  {props.t("Confirmer")}
                </button>
              </div>
            </Col>
          </div>

        </Form>
      </Row>
      <ToastContainer
          transition={Slide}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withTranslation()(AddFicheVoeuxSurveillance)
AddFicheVoeuxSurveillance.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
